import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { Row } from 'react-flexbox-grid'
import WhatsAppAsset from './assets/socials/whats_app'
import RedditAsset from './assets/socials/reddit'
import LinkedInAsset from './assets/socials/linked_in'
import TwitterAsset from './assets/socials/twitter'
import FacebookAsset from './assets/socials/facebook'
import { Box, makeStyles } from '@material-ui/core'

const reportShareEvent = (sentTo: string, title: string) => {
  //@ts-ignore
  if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
    //@ts-ignore
    window.gtag('event', 'blog_share', {
      event_label: sentTo,
      value: title,
    })
  }
}

const useStyles = makeStyles(theme => ({
  socialIcon: {
    padding: '8px',
    height: '24px',
    width: '24px',
    boxSizing: 'content-box',
    borderRadius: '20px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}))

const Share = ({ socialConfig, tags }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Row
        around="xs"
        style={{
          flexGrow: 1,
          padding: '0 50px',
        }}
      >
        <Box className={classes.socialIcon}>
          <FacebookShareButton
            beforeOnClick={() =>
              reportShareEvent('FB', socialConfig.config.title)
            }
            url={socialConfig.config.url}
            classes={classes.socialIcon}
          >
            <FacebookAsset />
          </FacebookShareButton>
        </Box>
        <Box className={classes.socialIcon}>
          <TwitterShareButton
            beforeOnClick={() =>
              reportShareEvent('Twitter', socialConfig.config.title)
            }
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            via={socialConfig.twitterHandle.split('@').join('')}
            hashtags={tags}
          >
            <TwitterAsset />
          </TwitterShareButton>
        </Box>
        <Box className={classes.socialIcon}>
          <LinkedinShareButton
            beforeOnClick={() =>
              reportShareEvent('LinkedIn', socialConfig.config.title)
            }
            url={socialConfig.config.url}
            title={socialConfig.config.title}
          >
            <LinkedInAsset />
          </LinkedinShareButton>
        </Box>
        <Box className={classes.socialIcon}>
          <RedditShareButton
            beforeOnClick={() =>
              reportShareEvent('Reddit', socialConfig.config.title)
            }
            url={socialConfig.config.url}
            title={socialConfig.config.title}
          >
            <RedditAsset />
          </RedditShareButton>
        </Box>
        <Box className={classes.socialIcon}>
          <WhatsappShareButton
            beforeOnClick={() =>
              reportShareEvent('WhatsApp', socialConfig.config.title)
            }
            url={socialConfig.config.url}
            title={socialConfig.config.title}
          >
            <WhatsAppAsset />
          </WhatsappShareButton>
        </Box>
      </Row>
    </React.Fragment>
  )
}
Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
Share.defaultProps = {
  tags: [],
}

export { Share }
