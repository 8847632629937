import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import { Share } from '../components/share'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '740px',
    padding: '0 20px 64px',
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
    fontFamily:
      '"Ubuntu",Lora,medium-content-sans-serif-font,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif',
    letterSpacing: '0',
    fontWeight: '400',
    fontStyle: 'normal',
    textRendering: 'optimizeLegibility',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    MozFontFeatureSettings: '"liga" on',
    color: 'rgba(0,0,0,.84)',
    fontSize: '20px',
    lineHeight: '1.4',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  image: {
    paddingTop: '35%',
    backgroundPosition: 'top',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '73%',
    },
  },
  blogTitle: {
    margin: '0 auto',
    paddingTop: '60px',
    textAlign: 'center',
    maxWidth: '1200px',
    fontFamily:
      '"Ubuntu", Oswald, medium-content-title-font, Georgia, Cambria, "Times New Roman", Times, serif',
    fontWeight: '700',
    letterSpacing: '0',
    lineHeight: 'normal',
    fontStyle: 'normal',
    fontSize: '45px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
      fontSize: '36px',
    },
  },

  blogBody: {
    '& table, th, td': {
      marginTop: theme.spacing(1),
      border: '1px solid black',
      borderCollapse: 'collapse',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(1),
    },
    '& figcaption':{
      fontSize:'14px',
      textAlign:'center',
      background:'#f0f0f0',
      borderRadius:'5px',
      fontWeight:'bold'

    },
    '& p': {
      fontFamily: '"Roboto", "Ubuntu", "Helvetica", "Arial", sans-serif',
      marginTop: '29px',
      // -XHeightMultiplier: '0.375',
      // -BaselineMultiplier: '0.17',
      lineHeight: '1.5',
      letterSpacing: '-.003em',
      marginBottom: '0',
      wordBreak: 'break-word',
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      MozFontFeatureSettings: '"liga" on',
      color: 'rgba(0,0,0,.94)',
      '@media (max-width: 767px)': {
        marginTop: '21px',
        fontSize: '18px',
        lineHeight: '1.58',
        letterSpacing: '-.004em',
      },
    },
    '& h1,h2,h3,h4,h5': {
      fontFamily:
        '"Ubuntu", Oswald, medium-content-title-font, Georgia, Cambria, "Times New Roman", Times, serif',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: 'normal',
      fontStyle: 'normal',
    },
    '& h3,h4': {
      fontStyle: 'italic',
    },
  },
  bigAvatar: {
    width: '80px',
    height: '80px',
  },
}))
type QueryResult = any
interface Props {
  data: QueryResult //prop will be injected by the GraphQL query below.
}
export default function Template(props: Props) {
  const { data } = props
  const blogBodyRef = useRef('')
  const [minutes, setMinutes] = useState(5)
  const [showMarketingDialog, setShowMarketingDialog] = React.useState<boolean>(
    false
  )
  const classes = useStyles() as any
  const { post, site } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = post
  const { siteUrl, twitterHandle } = site.siteMetadata
  const postUrl = `${siteUrl}${frontmatter.path}`
  require('prismjs/themes/prism-tomorrow.css')
  const imgSrc =
    post.frontmatter.hero !== null
      ? post.frontmatter.hero.childImageSharp.fluid.src
      : undefined
  const twitterImgSrc = imgSrc
    ? `${siteUrl}${post.frontmatter.hero.childImageSharp.fixed.src}`
    : undefined

  useEffect(() => {
    setMinutes(0),
      //Trigger the modal to show after 60 seconds
      setTimeout(() => setShowMarketingDialog(true), 60 * 1000)
  }, [])

  return (
    <React.Fragment>
      <link
        href="https://fonts.googleapis.com/css?family=Lora&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
        rel="stylesheet"
      />

      <Layout
        pageTitle={frontmatter.title}
        pageDescription={frontmatter.description}
        ogImageUrl={twitterImgSrc}
      >
        <Helmet>
          {/* OpenGraph tags */}
          <title>{frontmatter.title}</title>
          <meta property="og:url" content={postUrl} />
          <meta property="og:type" content="article" />
          {/* Twitter Card tags */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:creator" content={twitterHandle} />
        </Helmet>

        <Typography variant="h1" className={classes.blogTitle}>
          {frontmatter.title}
        </Typography>
        <Container className={classes.container}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div>
              <a href="https://medium.com/@TalPerry">
                <Avatar
                  alt="Tal Perry"
                  src={data.tal.childImageSharp.fluid.src}
                  className={classes.bigAvatar}
                />
              </a>
            </div>
            <div
              style={{
                marginLeft: '12px',
                fontSize: '17px',
                fontWeight: 400,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  fontWeight: 500,
                  marginBottom: '4px',
                }}
              >
                <a
                  href="https://medium.com/@TalPerry"
                  style={{ textDecoration: 'none' }}
                >
                  Tal Perry
                </a>
              </div>
              <div>{Math.floor(minutes)} min read</div>
            </div>
            <Share
              socialConfig={{
                twitterHandle,
                config: {
                  url: `${siteUrl}${post.frontmatter.path}`,
                  title: frontmatter.title,
                },
              }}
            />
          </Grid>

          <Typography
            className={classes.blogBody}
            //@ts-ignore
            ref={blogBodyRef as any}
            align="justify"
          >
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Typography>
        </Container>
      </Layout>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        siteUrl
        twitterHandle
      }
    }
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        hero {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 400, height: 600) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }

    tal: file(relativePath: { eq: "team/tal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
